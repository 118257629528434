import React from 'react';
import { Link } from 'react-router-dom';
import abimg2 from '../../images/slider/cat.png';

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const About = (props) => {
  return (
    <section className={`wpo-about-section section-padding ${props.abClass}`}>
      <div className='container'>
        <div className='row align-items-center'>
          <div
            className='col-lg-6 col-md-12 col-12'
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <div className='wpo-about-wrap'>
              <div className='wpo-about-imgs'>
                <div className='div_back'>
                  <div className='div_frunt'>
                    <img src='img/multicards.png' alt='' className='img_style_animiOne' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-12 col-12'>
            <div className='wpo-about-text'>
              <h4>Welcome to BuzzCreditCard Consultancy Company</h4>
              <h2>Your trusted consultants, here for you.</h2>
              <p>
                Welcome to BuzzCreditCard Credit Cards! Discover smart solutions for modern spending with our innovative
                range tailored to your financial needs. Maximize your rewards starting today!
              </p>
              <Link onClick={ClickHandler} className='theme-btn' to='/about'>
                More About Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
